import React, { createContext, useContext, useReducer } from 'react'

import { createReducer } from '~/support/ReducerHelper'

const UserStateContext = createContext()
const UserDispatchContext = createContext()

const USER_ACTIONS = {
  FETCH_USER_DATA: 'FETCH_USER_DATA',
  SET_USER_DATA: 'SET_USER_DATA',
  ERROR_FETCH_USER_DATA: 'ERROR_FETCH_USER_DATA',
  CLEAR_USER: 'CLEAR_USER',
}

const userState = {
  userData: {},
  status: 'IDLE',
}

const reducer = createReducer(userState, {
  [USER_ACTIONS.FETCH_USER_DATA]: (state, _) => ({
    ...state,
    status: 'LOADING',
  }),

  [USER_ACTIONS.SET_USER_DATA]: (state, action) => ({
    ...state,
    status: 'IDLE',
    userData: action.payload,
  }),

  [USER_ACTIONS.CLEAR_USER]: () => ({
    status: 'IDLE',
    userData: {},
  }),
})

const UserProvider = ({ children, value }) => {
  const [state, dispatch] = useReducer(reducer, {
    userData: value.user,
  })

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

const useUserState = () => {
  const context = useContext(UserStateContext)

  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }

  return context
}

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext)

  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }

  return context
}

export { UserProvider, useUserState, useUserDispatch, USER_ACTIONS }
