import api, { apiWithoutToken } from '~/services/_config'

export const getUserDataWithoutToken = async ({ jwt }) => {
  try {
    const { data } = await apiWithoutToken.get('/users/me', {
      headers: { Authorization: `Bearer ${jwt}` },
    })

    return data
  } catch (error) {
    throw new Error('COULD_NOT_GET_USER_DATA', error)
  }
}

export const getUsersLisCountByAdmin = async ({ adminId, ...params }) => {
  try {
    const { data } = await api.get('/users/count', {
      params: {
        'admin_referrer.id': adminId,
        ...params,
      },
    })

    return data
  } catch (error) {
    console.log({ error })
    throw new Error('COULD_NOT_GET_USERS_COUNT_BY_ADMIN', error)
  }
}

export const createUser = async ({
  name,
  email,
  weight,
  adminId,
  username,
  ...body
}) => {
  return await api.post('/users', {
    name,
    email,
    weight,
    username,
    admin_referrer: adminId,
    blocked: false,
    confirmed: true,
    role: '606e3e5e08554709fb3b08b2',
    password: process.env.NEXT_PUBLIC_DEFAULT_PASSWORD,
    ...body,
  })
}

export const editUser = async ({
  id,
  name,
  email,
  weight,
  username,
  ...body
}) => {
  return await api.put(`/users/${id}`, {
    name,
    email,
    weight,
    username,
    ...body,
  })
}

export const getUsersListByAdmin = async ({
  adminId,
  offset,
  limit,
  ...params
}) => {
  try {
    const { data } = await api.get('/users', {
      params: {
        'admin_referrer.id': adminId,
        _limit: limit ?? 10,
        _start: offset ?? 0,
        _sort: 'createdAt:desc',
        ...params,
      },
    })

    return data
  } catch (error) {
    console.log({ error })
    throw new Error('COULD_NOT_GET_USERS_LIST_BY_ADMIN', error)
  }
}

export const getUsersListByAdminWithPagination = async ({
  adminId,
  offset,
  limit,
  ...params
}) => {
  try {
    const [users, totalItems] = await Promise.all([
      getUsersListByAdmin({ adminId, offset, limit, ...params }),
      getUsersLisCountByAdmin({ adminId, ...params }),
    ])

    return { result: users, totalItems }
  } catch (error) {
    console.log({ error })
    throw new Error('COULD_NOT_GET_USERS_LIST_BY_ADMIN_WITH_PAGINATION', error)
  }
}
