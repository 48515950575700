import { useEffect } from 'react'

import Router from 'next/router'

import { removeUserCookies } from '~/helpers/CookiesHelper'
import { AUTH_ACTIONS, useAuthDispatch } from '~/store/AuthContext/reducer'

export const SessionCheck = ({ children }) => {
  const authDispatch = useAuthDispatch()

  useEffect(() => {
    const cleanSessionData = () => {
      removeUserCookies()
      authDispatch({ type: AUTH_ACTIONS.CLEAR_AUTH_DATA })
    }

    const handleRouteChange = url => {
      const homePath = '/'
      url === homePath && cleanSessionData()
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return children
}
