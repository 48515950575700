import cookie from 'js-cookie'

export const setUserCookies = ({ token }) => {
  cookie.set('token', token)
}

export const getUserCookiesByName = ({ name }) => cookie.get(name)

export const removeUserCookies = () => {
  const cookiesToBeRemoved = ['token']

  cookiesToBeRemoved.forEach(cookies => cookie.remove(cookies))
}

export const parseBooleanValue = value => {
  if (value === undefined) return false

  return value.toLowerCase() === 'true'
}

export const userHasCookiesForRedirects = cookies => {
  const mustHaveCookies = [cookies.token]

  return mustHaveCookies.every(element => element !== undefined)
}
