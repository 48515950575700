import React, { createContext, useContext, useReducer } from 'react'

import { createReducer } from '~/support/ReducerHelper'
import { ACTIONS } from '~pages/formulario/context/reducer'

const AuthStateContext = createContext()
const AuthDispatchContext = createContext()

const AUTH_ACTIONS = {
  FETCH_AUTH: 'FETCH_AUTH',
  SET_AUTH_DATA: 'SET_AUTH_DATA',
  SET_TOKEN: 'SET_AUTH_DATA',
  CLEAR_AUTH_DATA: 'CLEAR_AUTH_DATA',
  FETCH_AUTH_ERROR: 'FETCH_AUTH_ERROR',
}

const initialState = {
  signed: false,
  loading: false,
  token: '',
}

const reducer = createReducer(initialState, {
  [AUTH_ACTIONS.FETCH_AUTH]: state => ({
    ...state,
    loading: true,
  }),
  [AUTH_ACTIONS.FETCH_AUTH_ERROR]: state => ({
    ...state,
    loading: false,
  }),
  [AUTH_ACTIONS.SET_AUTH_DATA]: state => ({
    ...state,
    signed: true,
    loading: false,
  }),
  [AUTH_ACTIONS.CLEAR_AUTH_DATA]: () => ({
    signed: false,
    loading: false,
    token: null,
  }),
  [AUTH_ACTIONS.SET_TOKEN]: state => ({
    ...state,
    token: ACTIONS.payload,
  }),
})

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

const useAuthState = () => {
  const context = useContext(AuthStateContext)

  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }

  return context
}

const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext)

  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }

  return context
}

export { AuthProvider, useAuthState, useAuthDispatch, AUTH_ACTIONS }
