import React from 'react'

import { PageTransition } from 'next-page-transitions'

const TIMEOUT = 400

const RouteTransition = ({ children }) => (
  <>
    <PageTransition
      timeout={TIMEOUT}
      classNames='page-transition'
      loadingDelay={0}
      loadingTimeout={{
        enter: TIMEOUT,
        exit: 0,
      }}
      loadingClassNames='loading-indicator'
    >
      {children}
    </PageTransition>
    <style jsx global>{`
      .page-transition-enter {
        opacity: 0;
      }
      .page-transition-enter-active {
        opacity: 1;
        transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
      }
      .page-transition-exit {
        opacity: 1;
      }
      .page-transition-exit-active {
        opacity: 0;
        transition: opacity ${TIMEOUT}ms;
      }
      .loading-indicator-appear,
      .loading-indicator-enter {
        opacity: 0;
      }
      .loading-indicator-appear-active,
      .loading-indicator-enter-active {
        opacity: 1;
        transition: opacity ${TIMEOUT}ms;
      }
    `}</style>
  </>
)

export default RouteTransition
