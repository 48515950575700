import Router from 'next/router'

import { removeUserCookies } from '~/helpers/CookiesHelper'

export const redirectTo = (destination, { res, status } = {}) => {
  if (res) {
    res.writeHead(status || 302, { Location: destination })
    res.end()
  } else {
    if (destination[0] === '/' && destination[1] !== '/') {
      return Router.push(destination)
    }
    window.location = destination
  }
}

export const redirectToHome = ctx => {
  removeUserCookies()
  return redirectTo(`/?redirect=${encodeURIComponent(ctx.asPath)}`, {
    res: ctx.res,
    status: 302,
  })
}
