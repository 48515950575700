import { useEffect } from 'react'

// import Router from 'next/router'

import { useUserState } from '~/store/UserContext/reducer'

export const setDataLayer = (data = {}) => {
  window.dataLayer?.push({
    ...data,
  })
}

const GoogleTagManager = ({ children }) => {
  const { userData } = useUserState()

  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     const routeChangedValue = 'routeChanged'

  //     global.dataLayer.push({
  //       event: routeChangedValue,
  //     })
  //     const routeChanged = new Event(routeChangedValue)
  //     window.dispatchEvent(routeChanged)
  //   }

  //   Router.events.on('routeChangeComplete', handleRouteChange)

  //   return () => {
  //     Router.events.off('routeChangeComplete', handleRouteChange)
  //   }
  // }, [])

  useEffect(() => {
    if (userData.id) {
      setDataLayer({ userId: userData.id, userRole: userData.role })
    }
  }, [userData])

  return children
}

export default GoogleTagManager
