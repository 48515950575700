import axios from 'axios'
import cookie from 'js-cookie'
import Router from 'next/router'

const isDevelopment = process.env.NEXT_PUBLIC_SETTINGS === 'development'

const serverHost = isDevelopment
  ? process.env.NEXT_PUBLIC_SHAPE_ACADEMY_LOCAL_API
  : process.env.NEXT_PUBLIC_SHAPE_ACADEMY_API

import { removeUserCookies } from '~/helpers/CookiesHelper'

const apiWithToken = axios.create({
  baseURL: serverHost,
})

export const apiWithoutToken = axios.create({
  baseURL: serverHost,
})

apiWithToken.interceptors.request.use(config => {
  const token = cookie.get('token')

  if (token) {
    const authorizationHeader = `Bearer ${token}`
    config.headers.Authorization = authorizationHeader
  }

  return config
})

const responseOnError = error => {
  if (error && error.response) {
    const {
      config: { url: service },
      status,
    } = error.response

    const isAllowedRoute = ['/auth/local'].some(url => service.includes(url))

    if (status === 401 && !isAllowedRoute) {
      removeUserCookies()
      return Router.push('/')
    }
  }

  return Promise.reject(error)
}

const setInstanceResponseInterceptors = instance => {
  instance.interceptors.response.use(response => response, responseOnError)
}

;[apiWithToken, apiWithToken].forEach(setInstanceResponseInterceptors)

export default apiWithToken
