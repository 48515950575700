import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import cookies from 'next-cookies'
import App from 'next/app'
import Head from 'next/head'
import NextNprogress from 'nextjs-progressbar'
import PropTypes from 'prop-types'

import GoogleTagManager from '~/components/GoogleTagManager'
import PageTransition from '~/components/PageTransition'
import { SessionCheck } from '~/components/SessionCheck'
import { userHasCookiesForRedirects } from '~/helpers/CookiesHelper'
import { redirectToHome } from '~/helpers/RedirectHelper'
import { getUserDataWithoutToken } from '~/services/user'
import { AuthProvider } from '~/store/AuthContext/reducer'
import { UserProvider } from '~/store/UserContext/reducer'

const Noop = ({ children }) => children

const metaTags = [
  {
    attributes: {
      charSet: 'utf-8',
    },
  },
  {
    attributes: {
      name: 'title',
      content: 'Shape Academy | Sua mudança começa agora',
    },
  },
  {
    attributes: {
      name: 'description',
      content: 'Aqui você constrói sua melhor versão!',
    },
  },
  {
    attributes: {
      name: 'keywords',
      content:
        'academia, fitness, mudança, shape, estudo, curso, alimentação, nutrição',
    },
  },
  {
    attributes: {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=5',
    },
  },
  {
    attributes: {
      name: 'theme-color',
      content: '#111111',
    },
  },
  {
    attributes: {
      property: 'og:type',
      content: 'website',
    },
  },
  {
    attributes: {
      property: 'og:url',
      content: 'https://www.shapeacademy.com.br/',
    },
  },
  {
    attributes: {
      property: 'og:title',
      content: 'Shape Academy | Sua mudança começa agora',
    },
  },
  {
    attributes: {
      property: 'og:description',
      content: 'Aqui você constrói sua melhor versão!',
    },
  },
  {
    attributes: {
      property: 'twitter:url',
      content: 'https://www.shapeacademy.com.br/',
    },
  },
  {
    attributes: {
      property: 'twitter:title',
      content: 'Shape Academy | Sua mudança começa agora',
    },
  },
  {
    attributes: {
      property: 'twitter:description',
      content: 'Aqui você constrói sua melhor versão!',
    },
  },
]

const setMetaTags = tag => (
  <meta
    {...tag.attributes}
    key={tag.attributes.name ?? tag.attributes.property}
  />
)

class MyApp extends App {
  constructor() {
    super(...arguments)
  }

  static async getInitialProps({ Component, ctx }) {
    let userData = {}
    let pageProps = {}

    if (ctx.pathname !== '/') {
      const userCookies = cookies(ctx)
      if (!userHasCookiesForRedirects(userCookies)) return redirectToHome(ctx)

      try {
        // eslint-disable-next-line no-extra-semi
        ;[userData] = await Promise.all([
          getUserDataWithoutToken({ jwt: userCookies.token }),
        ])
      } catch (error) {
        return redirectToHome(ctx)
      }
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ...ctx, userData })
    }

    return { pageProps, userData, hasError: false }
  }

  componentDidMount() {
    // MUI has 2 sheets: 1 for server-side and 1 for client-side
    // we don't want this duplication, so during initial client-side load,
    // attempt to locate duplicated server-side MUI stylesheets...
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      // ...and if they exist remove them from the head
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  render() {
    const { Component, pageProps, userData, err } = this.props

    const userPayload = {
      id: userData._id,
      email: userData.email,
      weight: userData.weight,
      role: userData.role?.name,
      username: userData.username,
      adminReferrer: userData.admin_referrer ?? null,
    }

    const Layout = Component.layout || Noop

    return (
      <>
        <Head>
          <title>Shape Academy</title>
          <meta name='viewport' content='initial-scale=1, width=device-width' />
          <link rel='icon' href='/assets/favicon.ico' />
          {metaTags.map(setMetaTags)}

          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PZMM6S9');`}
          </script>
        </Head>
        <AuthProvider>
          <UserProvider value={{ user: userPayload }}>
            <GoogleTagManager>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <SessionCheck>
                <Layout>
                  <NextNprogress
                    color='#29D'
                    startPosition={0.3}
                    stopDelayMs={200}
                    height={3}
                    showOnShallow={true}
                  />
                  <PageTransition>
                    <Component {...pageProps} err={err} />
                  </PageTransition>
                </Layout>
              </SessionCheck>
            </GoogleTagManager>
          </UserProvider>
        </AuthProvider>
      </>
    )
  }
}

export default MyApp

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}
