import { createReducer } from '~/support/ReducerHelper'

export const ACTIONS = {
  SET_MODAL_FEEDBACK: 'SET_MODAL_FEEDBACK',
  SET_FORM_FIELDS: 'SET_FORM_FIELDS',
  ERROR_ON_CREATE_DAILY_INFO: 'ERROR_ON_CREATE_DAILY_INFO',
}

const handlers = {
  [ACTIONS.SET_MODAL_FEEDBACK]: (state, action) => ({
    ...state,
    modal: action.payload,
  }),
  [ACTIONS.SET_FORM_FIELDS]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      ...action.payload,
    },
  }),
  [ACTIONS.ERROR_ON_CREATE_DAILY_INFO]: state => ({
    ...state,
    creatingDailyInfo: false,
    updatingFormFields: false,
  }),
}

const reducer = createReducer({}, handlers)

export default reducer
